// src/components/ShirtPreview.js
import React from 'react';

// Importing shirt base images
import slimFit from '../assets/shirts/slim-fit.png';
import regular from '../assets/shirts/regular.png';
import ample from '../assets/shirts/ample.png';
// Importing left sleeve images
import leftLongSleeve from '../assets/sleeves/left/long.png';
import leftShortSleeve from '../assets/sleeves/left/short.png';
import leftMuscleSleeve from '../assets/sleeves/left/muscle.png';

// Importing right sleeve images
import rightLongSleeve from '../assets/sleeves/right/long.png';
import rightShortSleeve from '../assets/sleeves/right/short.png';
import rightMuscleSleeve from '../assets/sleeves/right/muscle.png';

import rightRoundCuff from '../assets/cuffs/right/round.png';
import leftRoundCuff from '../assets/cuffs/left/round.png';
import rightFrenchCuff from '../assets/cuffs/right/french.png';
import leftFrenchCuff from '../assets/cuffs/left/french.png';

import diplomaticCollar from '../assets/collars/diplomatic.png';
import englishCollar from '../assets/collars/english.png';
import highButtonDownCollar from '../assets/collars/high-button-down.png';
import italianRoundCollar from '../assets/collars/italian-round.png';
import italianCollar from '../assets/collars/italian.png';
import semiFrenchCollar from '../assets/collars/semi-french.png';
import koreanCollar from '../assets/collars/korean.png';


const sleeveImages = {
    Lungo: [leftLongSleeve, rightLongSleeve],
    Corto: [leftShortSleeve, rightShortSleeve],
    'Sul Muscolo': [leftMuscleSleeve, rightMuscleSleeve],
  };
  

// Mapping types to their respective images
const shirtImages = {
    'Aderente': slimFit,
    'Normale': regular,
    'Ampio': ample,
  };
  const cuffImages = {
    Tondo: [leftRoundCuff, rightRoundCuff],
    Francese: [leftFrenchCuff, rightFrenchCuff]
  };
  const collarImages = {
Diplomatico: diplomaticCollar,
Inglese: englishCollar,
'Buttondown alto': highButtonDownCollar,
Italiano: italianCollar,
Koreano: koreanCollar,
'Italiano tondo': italianRoundCollar,
'SemiFrancese': semiFrenchCollar
  };

const ShirtPreview = ({ type, sleeve, collar, cuff }) => {
  const shirtImage = shirtImages[type];
  const leftSleeveImage = sleeveImages[sleeve][0];
  const rightSleeveImage = sleeveImages[sleeve][1];
  const leftRoundCuffImage = cuffImages[cuff][0];
  const rightRoundCuffImage = cuffImages[cuff][1];
  const collarImage = collarImages[collar];

return (

<div className="previewContainer relative flex justify-center items-center my-10 mx-5 h-[400px] w-[400px] border border-black"> <div className="absolute top-0 left-0 w-full h-full z-index: 1; object-contain">
      <img src={shirtImage} alt="shirt base" className="absolute top-0 left-0 w-full h-full -z-20 object-contain" />
        <img src={leftSleeveImage} alt="left sleeve" className="absolute top-0 left-0 w-full h-full -z-30 object-contain"/>
        <img src={rightSleeveImage} alt="right sleeve" className="absolute top-0 left-0 w-full h-full object-contain -z-10"/>
           {/* Render cuff only if long sleeve is selected */}
           {sleeve === 'Lungo' && <img src={leftRoundCuffImage}  className="absolute top-0 left-0 w-full h-full object-contain" />}
           {sleeve === 'Lungo' && <img src={rightRoundCuffImage} className="absolute top-0 left-0 w-full h-full z-30; object-contain" />}
        <img src={collarImage} alt="collar" className='absolute top-0 left-0 w-full h-full z-30 object-contain' />
      </div>
      </div>
  );
};

export default ShirtPreview;
