// src/App.js
import React, { useState } from 'react';
import Dropdown from './components/Dropdown';
import Collar from './components/Collar';

import ShirtSelector from './components/ShirtSelector';
import ShirtPreview from './components/ShirtPreview';
import semiFrenchIcon from './assets/icons/semi-french.png';
import italianIcon from './assets/icons/italian.png';
import koreanIcon from './assets/icons/korean-medium.png';
import englishIcon from './assets/icons/english.png';
import highButtonDownIcon from './assets/icons/high-button-down.png';
import italianRoundIcon from './assets/icons/italian-round.png';
import diplomaticIcon from './assets/icons/diplomatic.png';

const App = () => {
  const [shirtType, setShirtType] = useState('Ampio');
  const [sleeveType, setSleeveType] = useState('Lungo');
  const [selectedCollarType, setSelectedCollarType] = useState('SemiFrancese');
  const [cuffType, setCuffType] = useState('Tondo');
  const shirtTypesDropDown = [
    { label: 'Ampio' },
    { label: 'Normale' },
    { label: 'Aderente' }

  ];
  const sleeveTypesSelection = [
    { label: 'Lungo' },
    { label: 'Corto' },
    { label: 'Sul Muscolo'},

  ];
  const cuffTypeSelection = [
    { label: 'Tondo'  },
    { label: 'Francese'}
  ];

  return (
    <div className="App ">
      <div className="CamiciaSuMisura text-[40px] mt-10 mb-10 text-center text-[50px] text-[#333333] text-lg font-normal font-['Rosario']">
        Camicia su misura
      </div>
      <div className='flex justify-between'>
        <div className="flex-col  ml-10">
          <div className="PersonalizzaLaTuaCamicia text-[#333333] text-[28px] font-normal font-['Rosario']">
            Personalizza la tua camicia
            <div className="flex">
              <div className="text-[#333333] text-[16px] font-normal font-['Rosario']">
                <Dropdown onTypeChange={setShirtType} type={shirtType} label="Vestiblita'" items={shirtTypesDropDown} />
                <Dropdown onTypeChange={setSleeveType} type={sleeveType} label="Maniche" items={sleeveTypesSelection} />
                <Dropdown onTypeChange={setCuffType} type={cuffType} label="Cuff Type" items={cuffTypeSelection} />
                <div>

                </div>

              </div>
            </div>
          </div>
          <h3 className="text-[18.72px] font-bold  text-[#333333] pt-10 font-['Rosario']">
            Colletto:
            </h3>
            <div className='flex'>
            <Collar
              onCollarChange={setSelectedCollarType} selectedCollarType={selectedCollarType} collarType="SemiFrancese" icon={semiFrenchIcon}/>
            <Collar  onCollarChange={setSelectedCollarType} selectedCollarType={selectedCollarType} collarType="Italiano" icon={italianIcon}/>
            <Collar  onCollarChange={setSelectedCollarType} selectedCollarType={selectedCollarType} collarType="Italiano tondo" icon={italianRoundIcon}/>
            <Collar  onCollarChange={setSelectedCollarType} selectedCollarType={selectedCollarType} collarType="Koreano" icon={koreanIcon}/>
            <Collar  onCollarChange={setSelectedCollarType} selectedCollarType={selectedCollarType} collarType="Buttondown alto" icon={highButtonDownIcon}/>
            <Collar  onCollarChange={setSelectedCollarType} selectedCollarType={selectedCollarType} collarType="Inglese" icon={englishIcon}/>
            <Collar  onCollarChange={setSelectedCollarType} selectedCollarType={selectedCollarType} collarType="Diplomatico" icon={diplomaticIcon}/>
            </div>
        </div>
        <div className='flex flex-col'>
        <div className="text-center mr-10 -mb-10 text-[28px] font-normal font-['Rosario'] text-[#333333]">
          Anteprima
        </div>
        <div className="">
        <ShirtPreview
        type={shirtType}
        sleeve={sleeveType}
        collar={selectedCollarType}
        cuff={cuffType}
      />
      </div>
        </div>
      </div>
    </div>
  )
};

export default App;
