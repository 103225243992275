import React, { useState } from 'react';

function Dropdown({ label,onTypeChange, type, items }) {

  return (
    <div>
      <label
        htmlFor={label.toLowerCase().replace(' ', '-')}
        className="text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <select
        id={label.toLowerCase().replace(' ', '-')}
        name={label.toLowerCase().replace(' ', '-')}
        value={type}
        onChange={(e) => onTypeChange(e.target.value)}
        className="bg-slate-200 border-black-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        {items.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>

    </div>
  );
}

export default Dropdown;