import React from 'react';


const Collar = ({ onCollarChange, collarType, selectedCollarType, icon}) => {
return (
<div c className='m-2'>
<label className="p-1">
            <input
              type="radio"
              name="collar"
              value={collarType}
              checked={collarType === selectedCollarType}
              onChange={(e) => onCollarChange(e.target.value)}
            />
            <img className='w-[77px] h-[77px]' src={icon}  />
            <span className="font-['Rosario'] text-[16px] ">{collarType}</span>
          </label>
</div>
);
};
export default Collar;
